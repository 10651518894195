import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';


const ParentEnfant = ({ data }) => {
  const quote = <><div style={{ color:"orange" }}>"Tu apporteras plus à un enfant en énonçant ses qualités qu'en lui reprochant ses défauts..."</div></>;
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;

  return (
    <Layout>
      <SEO title="Un parent et son enfant" />
      <Header metadata={data.site.siteMetadata} />
      {prereq}
      <br/><br/>
      Rien de tel que des moments à deux pour renforcer un lien, les ateliers "Dans la peau de l'autre" ont été
      pensé pour ça.<br/>
      Venez vivre un moment de partage entre un parent et un enfant.<br/>
      Un temps pour se retrouver, partager, communiquer et vivre dans la peau de l'autre.<br/><br/>
      * Comment je me sens dans ma relation à toi.<br/>
      * Comment tu te sens dans ta relation à moi.<br/><br/>
      Un espace parent-enfant ouvrant le dialogue et permettant de se retrouver, se rapprocher, se découvrir
      et s'ouvrir à l'autre.
      <br/><br/><br/>
      {quote}
    </Layout>
  );
};

export default ParentEnfant;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
